/* stylelint-disable declaration-empty-line-before, order/properties-alphabetical-order */

@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/fonts';

.modal__bg {
  @include z-index(modal);
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.modal__bg--default {
  background-color: rgba(0, 0, 0, 0.6);
}

.reveal-close-button {
  @include font(35);
  line-height: 1em;
  color: $grey-100;
  position: absolute;
  right: 9px;
}

.modal__close-button {
  position: absolute;
  font-weight: 200;
  color: $grey-500;
  top: 0;
  right: 0;
  line-height: 3rem;
  font-size: 2rem;
  width: 3.5rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $grey-100;
  }
}

// Google Places Autocomplete
.pac-container {
  .pac-item {
    padding: 14px 20px 6px;
    color: $black;

    // Hide results after 2 results
    // to save space on mobile
    &:nth-child(1n + 3) {
      display: none;
    }

    &:hover {
      background-color: $grey-500;
    }

    .pac-icon-marker {
      display: none;
    }

    span {
      font-size: 13px;
    }

    .pac-item-query {
      color: $black;
      line-height: 13px;
      display: block;
    }
  }
}
