$use_maison: true;
@import '@everlane/typography/scss';
@import '@everlane/pagination/index.scss';
@import '@everlane/button/index.scss';
@import '@everlane/icon/index.scss';
@import '@everlane/link/index.scss';
@import '@everlane/color/scss';
@import '@everlane/builder/index.scss';
@import '@everlane/form/index.scss';
@import '@everlane/carousel/index.scss';
@import '@everlane/color/utility-classes';
@import '@everlane/floating-box/index.scss';
@import 'styles/mixins';
@import 'styles/fonts';
@import 'styles/address_autocomplete';
@import 'styles/accessi_be.scss';
@import 'styles/fresnel.scss';
@import 'react-loading-skeleton/dist/skeleton.css';

:root {
  font-size: 10px;
}

body {
  @include font($body-small);

  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

a,
button {
  color: $grey-600;
  cursor: pointer;
}

.ReactModal__Content--after-open {
  -webkit-overflow-scrolling: touch;
}

.disable-page-scroll {
  overscroll-behavior: none;
}

.disable-page-scroll_ios {
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
}

select,
button {
  background-color: transparent;
  border: none;
  font: inherit;
  outline: none;
}

input[type='date'] {
  -webkit-appearance: none;
}

#afterpay-modal-overlay {
  #afterpay-modal-modal,
  #afterpay-modal-modal * {
    background: white !important;
  }
}

#afterpay-harvey-balls {
  display: none;
}
